//引入安装的axios插件
import axios from 'axios'
import Qs from 'qs'
import router from '../router/router'
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';


const http = options => {
    // 判断请求路径中是否是自己后端的接口
    let front=options.url.indexOf('front/')//前台接口前缀为front
    let back=options.url.indexOf('back/')//后台接口前缀为back
    // let baseURL = 'http://192.168.1.110:8088/kitchenac/';//线下
    let baseURL = 'http://www.jujiangmofang.cn/kitchenac/';//线上
    // 如果front开头和back开头的url都找不到说明用的外部接口,baseURL重置为空,使用代理解决跨域
    if(front==-1&&back==-1){
        console.log(baseURL)
        baseURL=''
    }
    return new Promise((resolve, reject) => {  
        axios({
            method: options.method || 'get',
            url:baseURL+options.url,
            // 如果参数为FormData格式则不处理
            data:Object.prototype.toString.call(options.data) != '[object FormData]'?Qs.stringify(options.data):options.data,
            params:options.params,
            headers: {
                'content-type': !options.method || options.method == 'GET' ? 'application/json' :'application/x-www-form-urlencoded;charset=UTF-8',
                token:sessionStorage.getItem('token'),
            },
        })
        .then(res => {
            let { status ,data}=res
            //根据返回的状态码判断，注意res返回的并不一定都是status，比如小程序就是statusCode
            if (status == 200) { 
                if(data.status=='500'){
                    console.log(500)
                    Message.error(data.msg)
                    resolve(res) 
                    return
                }else{
                    resolve(res) 
                    return 
                }
            } else {
                reject(res);
            }
        }).catch(err => {
            reject(err);
        })
    }
    )
};
export default http
