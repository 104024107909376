import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)

}
// router.beforeEach((to,from,next) =>{
//   if(to.query.code){
//     router.app.$options.store.dispatch('getCode',
//     to.query.code,
//     )
//   }
// })
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/index",
    },

    {
      path: "/index",
      component: () => import("../views/index.vue"),
      children: [],
      meta: {
        keepAlive: true,
        transition: true,
      },
    },
    ,
    {
      path: "/goodList",
      component: () =>
        import(/* webpackChunkName: "goodList" */ "../views/goodList"),
    },
    {
      path: "/browsingHistory",
      component: () =>
        import(/* webpackChunkName: "goodList" */ "../views/browsingHistory"),
    },
    {
      path: "/goodMessage",
      component: () =>
        import(/* webpackChunkName: "goodMessage" */ "../views/goodMessage"),
    },
    {
      path: "/chatRoom",
      component: () =>
        import(/* webpackChunkName: "chatRoom" */ "../views/chatRoom"),
      meta: {
        footer: true,
      },
    },
    {
      path: "/datailFourEvaluateDetail",
      component: () =>
        import(
          /* webpackChunkName: "datailFourEvaluateDetail" */ "../components/goodMessage/datails/datailFourEvaluateDetail.vue"
        ),
    },
    {
      path: "/goodCar",
      component: () =>
        import(/* webpackChunkName: "goodCar" */ "../views/goodCar"),
    },

    {
      path: "/payList",
      component: () =>
        import(/* webpackChunkName: "payList" */ "../views/payList"),
    },
    {
      path: "/payLists",
      component: () =>
        import(/* webpackChunkName: "payList" */ "../views/payLists"),
    },
    {
      path: "/blank",
      component: () => import("../views/blank"),
    },
    {
      path: "/orderList",
      component: () =>
        import(/* webpackChunkName: "orderList" */ "../views/orderList"),
    },
    {
      path: "/orderListDetails",
      component: () =>
        import(
          /* webpackChunkName: "orderListDetails" */ "../components/orderList/orderListDetails.vue"
        ),
    },
    {
      path: "/orderSucc",
      component: () =>
        import(/* webpackChunkName: "orderSucc" */ "../views/orderSucc"),
    },
    {
      path: "/subset",
      component: () =>
        import(/* webpackChunkName: "subset" */ "../views/subset"),
    },
    {
      path: "/myCenter",
      component: () =>
        import(/* webpackChunkName: "myCenter" */ "../views/my-center"),
    },
    {
      path: "/scoreshop",
      component: () =>
        import(/* webpackChunkName: "scoreshop" */ "../views/scoreshop"),
    },
    {
      path: "/company",
      component: () =>
        import(/* webpackChunkName: "company" */ "../views/company"),
    },
    {
      path: "/pay",
      component: () => import(/* webpackChunkName: "company" */ "../views/pay"),
    },
    {
      path: "/payCreditBill",
      component: () =>
        import(/* webpackChunkName: "company" */ "../views/payCreditBill"),
    },
    {
      path: "/payTimeCreditBill",
      component: () =>
        import(/* webpackChunkName: "company" */ "../views/payTimeCreditBill"),
    },
    {
      path: "/wallBulletin",
      component: () =>
        import(/* webpackChunkName: "wallBulletin" */ "../views/wallBulletin"),
    },
    {
      path: "/wallBulletinSubmit",
      component: () =>
        import(
          /* webpackChunkName: "wallBulletinSubmit" */ "../views/wallBulletinSubmit"
        ),
    },
    {
      path: "/wallBulletinItem",
      component: () =>
        import(
          /* webpackChunkName: "wallBulletin-item" */ "../views/wallBulletinItem"
        ),
    },
    {
      path: "/CompanyJoin",
      component: () =>
        import(/* webpackChunkName: "CompanyJoin" */ "../views/CompanyJoin"),
    },
    {
      path: "/CompanyJoinItem",
      component: () =>
        import(
          /* webpackChunkName: "CompanyJoinItem" */ "../views/CompanyJoinItem"
        ),
    },
    {
      path: "/MyClient",
      component: () =>
        import(/* webpackChunkName: "MyClient" */ "../views/MyClient"),
    },
    {
      path: "/MyClientDetail",
      component: () =>
        import(
          /* webpackChunkName: "MyClientDetail" */ "../views/MyClientDetail"
        ),
    },
    {
      path: "/partner",
      component: () =>
        import(
          /* webpackChunkName: "partner1" */ "../components/partner/partner.vue"
        ),
    },
    {
      path: "/certificate", //公司证书
      component: () =>
        import(/* webpackChunkName: "certificate" */ "../views/certificate"),
    },
    {
      path: "/companyInfo", //公司信息
      component: () =>
        import(/* webpackChunkName: "companyInfo" */ "../views/companyInfo"),
    },
    {
      path: "/addcertificate",
      component: () =>
        import(
          /* webpackChunkName: "addcertificate" */ "../views/addcertificate"
        ),
    },
    {
      path: "/NewchildList",
      component: () =>
        import(/* webpackChunkName: "NewchildList" */ "../views/NewchildList"),
    },
    {
      path: "/setInfo",
      component: () =>
        import(/* webpackChunkName: "setInfo" */ "../views/setInfo"),
    },
    {
      path: "/setInfoissue",
      component: () =>
        import(/* webpackChunkName: "setInfoissue" */ "../views/setInfoissue"),
    },
    {
      path: "/private",
      component: () =>
        import(/* webpackChunkName: "private" */ "../views/private"),
    },
    {
      path: "/wallBulletinDetail",
      component: () =>
        import(
          /* webpackChunkName: "wallBulletinDetail" */ "../views/wallBulletinDetail"
        ),
    },
    {
      path: "/wallBulletinSubmitDetail",
      component: () =>
        import(
          /* webpackChunkName: "wallBulletinSubmitDetail" */ "../views/wallBulletinSubmitDetail"
        ),
    },
    // 业务员申请加入经销商记录
    {
      path: "/applicationRecord",
      component: () =>
        import(
          /* webpackChunkName: "applicationRecord" */ "../views/applicationRecord"
        ),
    },
    {
      path: "/addReview",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/addReview"),
    },
    {
      path: "/goodListInfo",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/goodListInfo"),
    },
    {
      path: "/lookgoodListInfo",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/lookgoodListInfo"),
    },
    {
      path: "/wallBullentinMybusiness",
      component: () =>
        import(
          /* webpackChunkName: "addReview" */ "../views/wallBullentinMybusiness"
        ),
    },
    {
      path: "/information",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/information"),
    },
    {
      path: "/shoppingList",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/shoppingList"),
    },
    {
      path: "/purchaseOrderList",
      component: () =>
        import(
          /* webpackChunkName: "addReview" */ "../views/purchaseOrderList"
        ),
    },
    {
      path: "/applyFor",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/applyFor"),
    },

    {
      path: "/IncUpdate",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/IncUpdate"),
    },
    {
      path: "/searchWorker",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/searchWorker"),
    },
    {
      path: "/searchWorkers",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/searchWorkers"),
    },
    {
      path: "/resume",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/resume"),
    },
    {
      path: "/searchTotal",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/searchTotal"),
    },
    {
      path: "/ReleaseWorkOrder",
      component: () =>
        import(/* webpackChunkName: "addReview" */ "../views/ReleaseWorkOrder"),
    },
  ],
});

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }

export default router;
