<template>
  <div class="cityCascade">
    <el-cascader-panel
      ref="cascader"
      v-model="value"
      :options="options"
      :props="{ expandTrigger: 'hover' }"
      @change="handleChange"
      getCheckedNodes
    ></el-cascader-panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: {},
    };
  },
  methods: {
    //   change事件
    handleChange() {
      // 获取value
      console.log(this.value);
      // 获取lable
      let lable = this.$refs["cascader"].getCheckedNodes()[0].pathLabels;
      this.$emit("getCity", this.value, lable, false);
    },
  },
  computed: {
    options() {
      let city = JSON.parse(sessionStorage.getItem("allAreaData"));
      city.forEach((v) => {
        v.children.forEach((v) => {
          delete v.children;
        });
      });
      return city;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>