import Vue from 'vue'
import App from './App.vue'
import router from "./router/router";
import {store} from "./store/index";
// 时间处理
import moment from 'moment/moment'

// 配置elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import Myheader from "./components/my-header.vue"
// import socket from "./utils/index"
import * as socket from './utils/webSocket'
Vue.prototype.socket = socket
Vue.prototype.$moment = moment
Vue.component('my-header',Myheader)


// import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
// 注册头部组件为公共组件
Vue.config.productionTip = false
import Axios from 'axios'
import http from './api/axios_common.js'
import VueAxios from "vue-axios";
import { setTimeout } from 'core-js';
import { message } from './utils/resetMessage'

Vue.use(ElementUI)
Vue.prototype.$message = message;

// http://www.jujiangmofang.cn/kitchenac/
Axios.defaults.withCredentials = true
// 添加请求拦截器，在请求头中加token
Axios.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('token')) {
      config.headers.token = sessionStorage.getItem('token');
      config
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  });
Axios.interceptors.response.use(
  response=>{
    if(response.data.status==302){
      store.commit("setuserinfo",{});
      sessionStorage.setItem("roleId", "null");
      // 登录状态失效弹出登录框
      // store.commit('updateshowlogin',true)
      // 删除用户信息
      sessionStorage.setItem('userinfo','null')
      store.showlogin = true;
      if(store.state.showMessage){
        console.log(store.state.showMessage)
        store.commit('updateshowMessage',false)
        setTimeout(() => {
          ElementUI.Message({
          message:'登录状态失效,请重新登录',
          type:'error'
        })
        // window.location.reload();
        store.commit('updateshowMessage',true)
        }, 800);
      }
    }
    return response
  },
  error=>{
    console.log(error)
    return error
  }
)
Vue.prototype.$http = Axios
Vue.use(VueAxios, http);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
