import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    // 聊天室接收到的消息
    message:{},
    // token状态码
    token: sessionStorage.getItem('token'),
    // 购物车数量
    shopnum:0,
    //登录组件显示状态
    showlogin:false,
    // 提示消息的防抖
    showMessage:true,
    // 用户信息
    userinfo:{},
    // 当前选中城市信息
    checked:["","郑州"],
    checkeds:["","郑州"],
    companyId:"",
  },
  mutations: {
     // 修改聊天室接收到的消息
     updatemessage(state,obj){
      // console.log(obj)
      state.message=obj
    },
    //修改店铺companyId
    updateCompanyId(state,objs){
      // console.log(obj)
      state.companyId=objs
    },
    // 修改选中城市的信息
    updatechecked(state,val){
      state.checked=val;
      console.log(state,"-----"+val)
    },
    updatecheckeds(state,info){
      state.checkeds=info;
      console.log(state,info)
    },
    // 修改购物车数量
    altershopnum(state,num){
      state.shopnum=num
    },
    // 修改user信息
    setuserinfo(state,info){
      state.userinfo=info
    },
    // 修改登录组件的显示状态
    updateshowlogin(state,bool){
      state.showlogin=bool
      console.log(state,"------",bool)
    },
    // 修改提示消息的防抖状态
    updateshowMessage(state,bool){
      state.showMessage=bool
    }
  },
  actions:{
    async getshopnum(context,num){
      const res = await this.$http({
        method: "get",
        url: "front/cart/findList",
      });
      context.commit('altershopnum',res.data.data.cartProductVoList.length)
    }
  }
})
